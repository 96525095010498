import React from 'react';
import Slider from 'react-slick';
import { Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import css from './related-section.module.scss';
import shared from 'styles/shared.module.scss';

const resolveLink = item => {
  switch (item.type) {
    case 'blog_post':
      return 'blog';
    default:
      return '';
  }
};

const RelatedSection = ({ title, links }) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: links.length < 3 ? links.length : 3,
    slidesToScroll: 1,
    dots: true,
  };

  return (
    <section className={css.host}>
      <div className={shared.maxwidth}>
        <h3 className={shared.sectionTitle}>{title}</h3>
        <Slider {...settings} className={css.list}>
          {links.map(link => (
            <li key={link.uid} className={css.item}>
              <Link
                to={`/${resolveLink(link)}/${link.uid}`}
                className={css.itemLink}
              >
                <BackgroundImage className={css.itemImage} fluid={link.image}>
                  <div className={css.itemInfo}>
                    <h4 className={css.itemTitle}>hello</h4>
                  </div>
                </BackgroundImage>
              </Link>
            </li>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default RelatedSection;
