import React from "react"

const PlusIcon = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 28 28"
  >
    <g fill="none" fillRule="evenodd">
      <circle cx="14" cy="14" r="14" fill="#C83E3E" />
      <path
        stroke="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
        d="M14 9.917v8.166M9.917 14h8.166"
      />
    </g>
  </svg>
)

export default PlusIcon;

