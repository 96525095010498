import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import Img from 'gatsby-image';

import Hero from 'components/Hero';
import { resolveSlice } from 'utils/slice-resolver.js';
import LocationIcon from 'components/Icons/LocationIcon';
import PhoneIcon from 'components/Icons/PhoneIcon';
import MailIcon from 'components/Icons/MailIcon';
import LanguageSwitcher from 'components/LanguageSwitcher';

import a2Logo from 'images/a2-inside.png';

import css from './a2-inside-customer.module.scss';
import shared from 'styles/shared.module.scss';

const getHomePath = lang => (lang !== 'pt-pt' ? `/jp` : '/');

const A2InsideCustomer = ({ data: { prismic }, pageContext }) => {
  const [isMobileOpen, toggleMenuOpen] = useState(false);
  const content = prismic.allA2_inside_customers.edges[0];
  if (!content) return null;

  const page = content.node;
  const image = page.hero_imageSharp;
  const slices = page.body || [];
  const products = page.products || [];
  const logo = page.header_logo ? page.header_logo.url : '';

  return (
    <>
      <header className={css.header} role="presentation">
        <div className={`${css.headerContent} ${shared.maxwidth}`}>
          <div className={css.logos}>
            <Link to={getHomePath(pageContext.lang)}>
              <img className={css.navLogo} src={a2Logo} alt="A2Inside logo" />
            </Link>
            <img className={css.navLogo} src={logo} alt="logo" />
          </div>
          <nav className={css.nav}>
            <ul className={css.navMenu}>
              <li className={css.navItem} role="presentation">
                <a href="#company">{page.header_company}</a>
              </li>
              <li className={css.navItem} role="presentation">
                <a href="#products">{page.header_products}</a>
              </li>
              <li className={css.navItem} role="presentation">
                <a href="#sale">{page.header_sales}</a>
              </li>
              <li className={css.navItem} role="presentation">
                <a href="#quality">{page.header_quality}</a>
              </li>
              <li className={css.navItem} role="presentation">
                <a href="#contacts">{page.header_contacts}</a>
              </li>
            </ul>
            <LanguageSwitcher
              className={css.switcher}
              urls={[
                { lang: 'en', url: `/${pageContext.uid}` },
                { lang: 'jp', url: `/jp/${pageContext.uid}` },
              ]}
            />
          </nav>
          <button
            className={`${css.navBtnMobile} ${
              isMobileOpen ? css.mobileOpen : ''
            }`}
            onClick={() => toggleMenuOpen(!isMobileOpen)}
          >
            <div className={css.line} />
            <div className={css.line} />
            <div className={css.line} />
          </button>
        </div>
      </header>
      <Hero
        image={image ? image.childImageSharp.fluid : null}
        logo={page.hero_logo ? page.hero_logo.url : ''}
        logoClassName={css.heroLogo}
        title={page.hero_title}
        subtitle={page.hero_subtitle}
        description={page.hero_description}
      />
      <section
        className={`${css.introSection} ${shared.maxwidth}`}
        id="company"
      >
        <div className={css.introContent}>
          <RichText render={page.description} />
        </div>
      </section>
      <section className={css.productsSection} id="products">
        <div className={shared.maxwidth}>
          <h2 className={shared.sectionTitle}>{page.products_title}</h2>
          <ul className={css.productsList}>
            {products.map((product, idx) => {
              return (
                <li key={idx} className={css.productsItem}>
                  <img
                    className={css.productLogo}
                    src={product.product_image.url}
                    alt="Logo"
                  />
                  <div className={css.productInfo}>
                    <h3 className={css.productName}>{product.product_name}</h3>
                    <p className={css.productDescription}>
                      <RichText render={product.product_description} />
                    </p>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </section>
      <section className={`${css.pointsSection} ${shared.maxwidth}`} id="sale">
        <div className={css.pointsContent}>
          <div className={css.valuesBkg} />
          <div className={css.valuesContainer}>
            {page.sales_imageSharp ? (
              <Img
                fluid={page.sales_imageSharp.childImageSharp.fluid}
                className={css.valuesImage}
                alt="Team"
              />
            ) : null}
            <div className={css.valuesContent}>
              <h2 className={shared.sectionTitle}>
                {RichText.asText(page.sales_title)}
              </h2>
              <ul className={css.valuesList}>
                {page.sales_points.map((point, idx) => (
                  <li key={`${idx + 1}`} className={css.valuesItem}>
                    <LocationIcon width={28} height={28} />
                    <div className={css.valuesInfo}>
                      <h4>{point.point_title}</h4>
                      <div>{point.point_description}</div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div id="quality">{slices.map(resolveSlice)}</div>
      <section className={css.certificateSection}>
        <div className={shared.maxwidth}>
          <h2 className={shared.sectionTitle}>{page.certificate_title}</h2>
          <p className={shared.sectionDescription}>
            {page.certificate_subtitle}
          </p>
          <Img
            fluid={page.certificate_imageSharp.childImageSharp.fluid}
            className={css.certificateImage}
            alt="Certificate"
          />
        </div>
      </section>
      <footer className={css.footer} id="contacts">
        <div className={css.footerBottom}>
          <div className={`${css.footerBottomContent} ${shared.maxwidth}`}>
            <div className={`${css.footerColumn} ${css.location}`}>
              <LocationIcon width={16} height={16} />
              <div className={css.footerText}>{page.address}</div>
            </div>
            <div className={`${css.footerColumn} ${css.contacts}`}>
              <div className={css.contact}>
                <PhoneIcon width={16} height={16} />
                <a href={`tel:${page.phone_number}`}>{page.phone_number}</a>
              </div>
              <div className={css.contact}>
                <MailIcon width={16} height={16} />
                <a href={`mailto:${page.email}`}>{page.email}</a>
              </div>
            </div>
            <img className={css.footerLogo} src={a2Logo} alt="A2Inside logo" />
          </div>
        </div>
      </footer>
    </>
  );
};

export const pageQuery = graphql`
  query A2InsideCustomersByUid($uid: String, $lang: String) {
    prismic {
      allA2_inside_customers(uid: $uid, lang: $lang) {
        edges {
          node {
            header_logo
            header_company
            header_products
            header_sales
            header_quality
            header_contacts
            description
            products_title
            products {
              product_description
              product_image
              product_name
            }
            sales_title
            sales_image
            sales_points {
              point_description
              point_title
            }
            sales_imageSharp {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            hero_title
            hero_subtitle
            hero_logo
            hero_description
            hero_image
            hero_imageSharp {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            certificate_title
            certificate_subtitle
            certificate_image
            certificate_imageSharp {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            body {
              ... on PRISMIC_A2_inside_customerBodyContent_section {
                type
                label
                fields {
                  main
                  content_text
                  image
                  imageSharp {
                    childImageSharp {
                      fluid(quality: 90, maxWidth: 800) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                  link_label
                  link {
                    ... on PRISMIC__FileLink {
                      url
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                }
                primary {
                  type
                  title
                }
              }
            }
            address
            phone_number
            email
            _linkType
          }
        }
      }
    }
  }
`;

export default A2InsideCustomer;
