import React from 'react';

const MailIcon = ({ width, height }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16">
    <g fill="none" fillRule="evenodd" stroke="#C83E3E" strokeLinecap="round" strokeLinejoin="round">
      <rect width="15.111" height="11.556" x=".444" y="2.222" rx="1.5" />
      <path d="M1.313 2.944L6.575 8.19a2 2 0 0 0 2.837-.012l5.159-5.235M6.107 8l-3.143 3.143M9.778 8l3.142 3.143" />
    </g>
  </svg>
);

export default MailIcon;