module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-455420-13","exclude":["/preview/**"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-hubspot/gatsby-browser.js'),
      options: {"plugins":[],"trackingCode":"3823430","respectDNT":true,"productionOnly":true},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"SwissDeCode","defaultLang":"en-us","accessToken":"MC5YWXFTM2hFQUFDWUFYaHVf.77-977-9cipbcRhC77-977-9aQ1977-9VO-_vXzvv73vv71e77-9Iu-_vXBj77-9LQte77-9W--_vQ","previews":true,"sharpKeys":[{},"profilepic"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
