import React from 'react';

const LinkedinShareIcon = ({ width, height, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    className={className}
    viewBox="0 0 16 16"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M12.396 4.864c1.222 0 2.204.406 2.947 1.216.699.763.664 1.767.658 3.187L16 15.938h-3.2l.132-5.888c0-.75-.144-1.337-.434-1.763-.29-.425-.74-.637-1.355-.637-.45 0-.827.123-1.13.37-.304.246-.796 1.154-.946 1.518-.079.214 0 6.4 0 6.4H5.333c.013-2.533.003-5.063 0-6.838V5.271l3.881-.16v1.543c.122-.229.268-.429.418-.6.15-.172.352-.358.606-.558.253-.2.564-.355.932-.466.368-.11.776-.166 1.226-.166zm-8.657.247v10.618H.204V5.11h3.535zM1.993 0c.593 0 1.068.173 1.425.52.357.346.54.784.546 1.312.007.522-.173.957-.54 1.307-.369.35-.853.525-1.453.525H1.95c-.586 0-1.057-.175-1.414-.525A1.756 1.756 0 010 1.832C0 1.304.184.866.552.52.92.173 1.4 0 1.992 0z"
    />
  </svg>
);

export default LinkedinShareIcon;
