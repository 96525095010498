import React from 'react';
import { Link } from 'gatsby';

import Button from 'components/Button';

import css from './404.module.scss';
import shared from 'styles/shared.module.scss';

const NotFound = () => (
  <section className={css.host}>
    <div className={`${css.container} ${shared.maxwidth}`}>
      <h1>Page not found</h1>
      <Link to="/">
        <Button filled>Return to home</Button>
      </Link>
    </div>
  </section>
);

export default NotFound;
