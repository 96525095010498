import React, { useState, useRef, useEffect, useCallback } from 'react';
import { RichText } from 'prismic-reactjs';
import classnames from 'classnames';

import Button from 'components/Button';

import css from './contact.module.scss';
import shared from 'styles/shared.module.scss';

const ContactSection = ({ type, title, buttonLabel, inverted, form }) => {
  const [showForm, setShowForm] = useState();
  const modalContentRef = useRef();
  const createdRef = useRef(false);

  const onDocumentClick = useCallback(evt => {
    if (
      modalContentRef.current &&
      !modalContentRef.current.contains(evt.target)
    ) {
      setShowForm(false);
    }
  }, []);

  const onDocumentKeyUp = useCallback(evt => {
    if (evt.key === 'Escape') {
      setShowForm(false);
    }
  }, []);

  useEffect(() => {
    if (showForm) {
      document.addEventListener('click', onDocumentClick);
      document.addEventListener('keyup', onDocumentKeyUp);

      return () => {
        document.removeEventListener('click', onDocumentClick);
        document.removeEventListener('keyup', onDocumentKeyUp);
      };
    }
  }, [showForm, onDocumentClick, onDocumentKeyUp]);

  const onOpenForm = () => {
    setShowForm(true);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (!createdRef.current && showForm) {
        let script = document.createElement(`script`);
        script.defer = true;
        script.onload = () => {
          window.hbspt.forms.create({
            region: 'na1',
            portalId: form.portal,
            formId: form.id,
            target: `#form-${form.id}`,
          });
        };
        script.src = `//js.hsforms.net/forms/v2.js`;
        document.head.appendChild(script);
        createdRef.current = true;
      } else if (createdRef.current && showForm) {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: form.portal,
          formId: form.id,
          target: `#form-${form.id}`,
        });
      }
    }
  }, [showForm, form]);

  return (
    <section className={classnames(css.host, { [css.inverted]: inverted })}>
      <div className={shared.maxwidth}>
        <h4 className={css.type}>{RichText.asText(type)}</h4>
        <h3 className={css.title}>{RichText.asText(title)}</h3>
        {form ? (
          <Button className={css.btn} onClick={onOpenForm}>
            {buttonLabel}
          </Button>
        ) : (
          <a href="#contact-us">
            <Button className={css.btn}>{buttonLabel}</Button>
          </a>
        )}
      </div>
      {showForm && (
        <div className={css.formModal}>
          <div
            id={`form-${form.id}`}
            className={css.formModalContent}
            ref={modalContentRef}
          ></div>
        </div>
      )}
    </section>
  );
};

export default ContactSection;
