import 'typeface-montserrat';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './src/styles/global.scss';

import React from 'react';
import Layout from './src/components/Layout';
import MinimalLayout from './src/components/MinimalLayout';

import { registerLinkResolver } from 'gatsby-source-prismic-graphql';
import { linkResolver } from './src/utils/link-resolver';

registerLinkResolver(linkResolver);

export const wrapPageElement = ({ element, props }) => {
  const { isExternal, noFooter } = props.pageContext;
  return isExternal ? (
    <MinimalLayout {...props} hasFooter={!noFooter}>
      {element}
    </MinimalLayout>
  ) : (
    <Layout {...props}>{element}</Layout>
  );
};

// export const onClientEntry = () => {
//   if (typeof window.IntersectionObserver === `undefined`) {
//     import(`intersection-observer`);
//   }
// };
