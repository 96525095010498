import React from 'react';
import Img from 'gatsby-image';
import { RichText } from 'prismic-reactjs';

import PlusIcon from 'components/Icons/PlusIcon';

import css from './elements-section.module.scss';
import shared from 'styles/shared.module.scss';

const ElementsSection = ({ type, title, elements }) => {
  return (
    <section className={`${css.host} ${shared.maxwidth}`}>
      <h4 className={shared.sectionType}>{RichText.asText(type)}</h4>
      <h2 className={shared.sectionTitle}>{RichText.asText(title)}</h2>
      <div className={css.elementsList}>
        {elements.map((element, idx) => (
          <div className={css.elementItem} key={`${idx + 1}`}>
            <Img className={css.elementImg} fluid={element.image} />
            <h3 className={css.elementTitle}>
              {RichText.asText(element.title)}
            </h3>
            <p className={css.elementDescription}>
              {RichText.asText(element.description)}
            </p>
            <div className={css.elementFeature}>
              <PlusIcon width={24} height={24} />
              <span>{element.key_1}</span>
            </div>
            <div className={css.elementFeature}>
              <PlusIcon width={24} height={24} />
              <span>{element.key_2}</span>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ElementsSection;
