import React from 'react';

const LocationIcon = ({ width, height }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16">
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#C83E3E"
      transform="translate(1.778)"
    >
      <path d="M6 15.155C9.673 11.057 11.5 7.978 11.5 6a5.5 5.5 0 0 0-11 0c0 1.978 1.827 5.057 5.5 9.155z" />
      <circle cx="6" cy="6" r="2" />
    </g>
  </svg>
);

export default LocationIcon;
