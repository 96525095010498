import React from 'react';

const FacebookIcon = ({ width, height }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 26 26">
    <g fill="none" fillRule="evenodd" transform="translate(1 1)">
      <circle cx="12" cy="12" r="12" stroke="#C83E3E" />
      <path fill="#C83E3E" d="M14.691 10.164h-2.087V8.64c0-.468.482-.573.713-.573h1.343v-2.06L13.117 6C11.01 6 10.53 7.532 10.53 8.518v1.646H9v2.12h1.53V18h2.074v-5.716h1.768l.32-2.12z" />
    </g>
  </svg>
);

export default FacebookIcon;