const openPopup = url => {
  const height = 585;
  const width = 940;

  const left = window.innerWidth / 2 - width / 2;
  const top = window.innerHeight / 2 - height / 2;

  const params = `
      location=1,status=1, scrollbars=1, resizable=1, directories=1, toolbar=1, titlebar=1,
      width=${width}, height=${height}, top=${top}, left=${left}
    `;

  return window.open(url, null, params);
};

const share = (
  network,
  link = window.location.href,
  title = '',
  description = ''
) => {
  const descriptionEncoded = encodeURIComponent(description);
  const titleEncoded = encodeURIComponent(title);
  const url = encodeURIComponent(link);

  let popup;

  if (network === 'facebook') {
    popup = `//www.facebook.com/sharer.php?u=${url}`;
  } else if (network === 'twitter') {
    popup = `//twitter.com/intent/tweet?text=${titleEncoded}&url=${url}`;
  } else if (network === 'linkedin') {
    popup = `//www.linkedin.com/shareArticle?mini=true&url=${url}&title=${titleEncoded}&summary=${descriptionEncoded}`;
  } else if (network === 'google') {
    popup = `//plus.google.com/share?url=${url}`;
  }

  if (popup) openPopup(popup);
};

export default {
  facebook: link => {
    share('facebook', link);
  },
  google: link => {
    share('google', link);
  },
  linkedin: (link, title, summary, source) => {
    share('linkedin', link, title, summary, source);
  },
  twitter: (link, text) => {
    share('twitter', link, text);
  },
};
