import React from 'react';

import ContentSection from 'components/ContentSection';
import FlowSection from 'components/FlowSection';
import MosaicSection from 'components/MosaicSection';
import ElementsSection from 'components/ElementsSection';
import VideoSection from 'components/VideoSection';
import GridSection from '../components/GridSection';
import ChildrenSection from '../components/ChildrenSection';
import ContactSection from 'components/ContactSection';
import SolutionsSection from 'components/SolutionsSection';
import PartnersSection from 'components/PartnersSection';
import RelatedSection from 'components/RelatedSection';
import FormSection from 'components/FormSection';
import BulletPointsSection from 'components/BulletPointsSection';
import FaqSection from 'components/FaqSection';
import RegisterSection from 'components/RegisterSection';

export const resolveSlice = (slice, idx) => {
  switch (slice.type) {
    default:
      return null;

    case 'flow_section':
      const flowData = slice.primary;
      const steps = (slice.fields || []).map(item => ({
        image: item.icon ? item.icon.url : null,
        title: item.name,
        description: item.text,
      }));
      return (
        <FlowSection
          key={idx}
          type={flowData.type}
          title={flowData.title}
          steps={steps}
        />
      );
    case 'mosaic_section':
      const mosaicData = slice.primary;
      const btns = slice.fields || [];
      return (
        <MosaicSection
          key={idx}
          type={mosaicData.type}
          title={mosaicData.title}
          description={mosaicData.description}
          images={[
            mosaicData.top_left_image,
            mosaicData.top_right_image,
            mosaicData.bottom_image,
          ]}
          buttons={btns}
          ltr={mosaicData.orientation === 'ltr'}
        />
      );
    case 'content_secton':
    case 'content_section':
      const contentData = slice.primary || {
        type: [],
        title: [],
        alignment: 'center',
      };
      const sections = (slice.fields || []).map(item => {
        return {
          image: item.imageSharp ? item.imageSharp.childImageSharp.fluid : null,
          logo: item.icon,
          link: item.link_label
            ? {
                label: item.link_label || '',
                url: item.link ? item.link.url : '',
              }
            : null,
          title: item.main,
          description: item.content_text,
        };
      });

      return (
        <ContentSection
          key={idx}
          type={contentData.type}
          title={contentData.title}
          alignment={contentData.alignment}
          sections={sections}
        />
      );
    case 'elements_section':
      const elementsPrimary = slice.primary || {
        elements_label: [],
        elements_title: [],
      };
      const elements = (slice.fields || []).map(item => {
        return {
          title: item.element_title,
          description: item.element_description,
          image: item.element_imageSharp
            ? item.element_imageSharp.childImageSharp.fluid
            : null,
          key_1: item.element_key_1,
          key_2: item.element_key_2,
        };
      });
      return (
        <ElementsSection
          key={idx}
          type={elementsPrimary.elements_label}
          title={elementsPrimary.elements_title}
          elements={elements}
        />
      );
    case 'video_section':
      const videoPrimary = slice.primary || {
        video_description: [],
        video_label: [],
        video_link: '',
        video_title: [],
      };

      return (
        <VideoSection
          key={idx}
          label={videoPrimary.video_label}
          title={videoPrimary.video_title}
          description={videoPrimary.video_description}
          video={videoPrimary.video_link}
        />
      );
    case 'grid_section':
      const gridPrimary = slice.primary || {
        grid_title: [],
        grid_label: [],
      };

      const gridColumns = (slice.fields || []).map(item => {
        return {
          title: item.grid_card_title || [],
          subtitle: item.grid_card_subtitle || [],
          description: item.grid_card_description || [],
          idx: item.grid_card_column,
        };
      });

      return (
        <GridSection
          key={idx}
          label={gridPrimary.grid_label}
          title={gridPrimary.grid_title}
          columns={gridColumns}
        />
      );
    case 'children_section':
      const childrenPrimary = slice.primary || {
        children_section_title: [],
        children_section_label: [],
      };

      const childArray = (slice.fields || []).map(item => {
        return {
          title: item.child_title || [],
          description: item.child_description || [],
          link: item.child_link
            ? {
                ...item.child_link._meta,
                categoryUid: item.child_link.product_category
                  ? item.child_link.product_category._meta.uid
                  : '',
              }
            : null,
          image: item.child_image ? item.child_image.url : null,
        };
      });

      return (
        <ChildrenSection
          key={idx}
          label={childrenPrimary.children_section_label}
          title={childrenPrimary.children_section_title}
          childArray={childArray}
        />
      );
    case 'contact_section':
      const contactPrimary = slice.primary || {
        type: [],
        title: [],
        button_label: '',
        inverted: false,
      };

      const hubspotForm =
        contactPrimary.hubspot_portal_id && contactPrimary.hubspot_form_id
          ? {
              portal: contactPrimary.hubspot_portal_id,
              id: contactPrimary.hubspot_form_id,
            }
          : null;

      return (
        <ContactSection
          key={idx}
          type={contactPrimary.type}
          title={contactPrimary.title}
          buttonLabel={contactPrimary.button_label}
          inverted={contactPrimary.inverted}
          form={hubspotForm}
        />
      );
    case 'solutions_section':
      const solutionsPrimary = slice.primary || {
        type: [],
        title: [],
        other_title: '',
        other_description: '',
        contact_label: '',
      };

      const solutionsArray = (slice.fields || []).map(item => {
        let link = null;
        if (item.solution_link) {
          const category = item.solution_link.product_category;
          if (category) {
            link = `/${category._meta.uid}/${item.solution_link._meta.uid}`;
          }
        }

        return {
          name: item.solution_name || '',
          image: item.solution_image ? item.solution_image.url : null,
          link,
        };
      });

      return (
        <SolutionsSection
          key={idx}
          type={solutionsPrimary.type}
          title={solutionsPrimary.title}
          otherTitle={solutionsPrimary.other_title}
          otherDescription={solutionsPrimary.other_description}
          contactLabel={solutionsPrimary.contact_label}
          solutions={solutionsArray}
        />
      );
    case 'partners_section':
      const partnersPrimary = slice.primary || {
        title: [],
        description: [],
        transparent: false,
      };

      const partnersLogoArray = (slice.fields || []).map(item => {
        return {
          name: item.logo ? item.logo.alt : '',
          image: item.logo ? item.logo.url : null,
          link: item.logo_url || null,
        };
      });

      return (
        <PartnersSection
          key={idx}
          title={partnersPrimary.title}
          description={partnersPrimary.description}
          transparent={partnersPrimary.transparent}
          logos={partnersLogoArray}
        />
      );
    case 'related_section':
      const relatedPrimary = slice.related_primary || {
        title: '',
      };

      const relatedLinksArray = (slice.fields || []).map(({ link }) => {
        return {
          uid: link._meta.uid,
          image: link.imageSharp ? link.imageSharp.childImageSharp.fluid : null,
          type: link._meta.type,
        };
      });

      return relatedLinksArray.length > 0 ? (
        <RelatedSection
          key={idx}
          title={relatedPrimary.title}
          links={relatedLinksArray}
        />
      ) : null;
    case 'form_section':
      const formPrimary = slice.primary || {
        form_section_id: '',
        form_label: '',
        form_title: '',
      };

      const formProps = {
        id: formPrimary.form_id || '',
        privacy: formPrimary.form_privacy || '',
        success: formPrimary.form_success || '',
      };

      const conversions = {
        linkedin: formPrimary.linkedin_conversion || '',
      };

      return (
        formProps.id && (
          <FormSection
            key={idx}
            sectionId={formPrimary.form_section_id}
            label={formPrimary.form_label}
            title={formPrimary.form_title}
            form={formProps}
            conversions={conversions}
          />
        )
      );
    case 'points_section':
      const pointsPrimary = slice.points_primary || {
        imageSharp: null,
        label: '',
        title: '',
      };
      const points = (slice.fields || []).map(
        field => field.bullet_point || ''
      );

      return (
        <BulletPointsSection
          key={idx}
          image={pointsPrimary.imageSharp}
          label={pointsPrimary.label}
          title={pointsPrimary.title}
          points={points}
        />
      );
    case 'faq_section':
      const faqsPrimary = slice.faq_primary || {
        label: '',
        title: '',
      };
      const faqs = slice.fields || [];

      return (
        <FaqSection
          key={idx}
          label={faqsPrimary.label}
          title={faqsPrimary.title}
          faqs={faqs}
        />
      );
    case 'registersection':
      const registerPrimary = slice.register_primary || {
        title: '',
        description: [],
        form_id: '',
      };

      const subforms = slice.fields || [];

      return (
        <RegisterSection
          key={idx}
          title={registerPrimary.title}
          description={registerPrimary.description}
          formId={registerPrimary.form_id}
          subForms={subforms}
        />
      );
  }
};
