import React from 'react';

import { getCookie } from 'utils/cookies';

import css from './contact-form.module.scss';

const PORTAL_ID = '3823430';
const FORM_ID = '6f9be333-ce48-48a0-8740-59407351b1d8';
const URL = `https://api.hsforms.com/submissions/v3/integration/submit/${PORTAL_ID}/${FORM_ID}`;
// const fields = ['firstname', 'lastname', 'email', 'phone', 'message'];

class ContactForm extends React.PureComponent {
  state = {
    valid: false,
    sending: false,
    statusMessage: '',
  };

  onSubmit = evt => {
    const { firstname, lastname, email, phone, message } = this.state;
    evt.preventDefault();

    var data = {
      submittedAt: Date.now(),
      fields: [
        {
          name: 'firstname',
          value: firstname,
        },
        {
          name: 'lastname',
          value: lastname,
        },
        {
          name: 'email',
          value: email,
        },
      ],
      context: {
        hutk: getCookie('hubspotutk'),
      },
      legalConsentOptions: {
        consent: {
          consentToProcess: true,
          text:
            'I agree to allow SwissDeCode to store and process my personal data.',
          communications: [
            {
              value: true,
              subscriptionTypeId: 999,
              text:
                'I agree to receive marketing communications from SwissDeCode.',
            },
          ],
        },
      },
    };

    if (phone && phone.length) {
      data.fields.push({
        name: 'phone',
        value: phone,
      });
    }

    if (message && message.length) {
      data.fields.push({
        name: 'message',
        value: message,
      });
    }

    const final_data = JSON.stringify(data);
    const xhr = new XMLHttpRequest();

    this.setState({
      sending: true,
    });

    xhr.open('POST', URL);
    xhr.setRequestHeader('Content-Type', 'application/json');

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        this.setState({
          statusMessage:
            'Thank you for your message. We will be in touch shortly.',
        });
      } else if (xhr.readyState === 4 && xhr.status === 400) {
      } else if (xhr.readyState === 4 && xhr.status === 403) {
      } else if (xhr.readyState === 4 && xhr.status === 404) {
      }

      this.setState({
        sending: false,
      });
    };

    // Sends the request
    xhr.send(final_data);
  };

  onChangeInput = evt => {
    this.setState({
      [evt.target.name]: evt.target.value,
      statusMessage: '',
    });
  };

  onChangeCheckbox = evt => {
    evt.stopPropagation();
    const checked = evt.target.checked;
    this.setState({
      valid: checked,
    });
  };

  render() {
    const {
      firstname,
      lastname,
      email,
      phone,
      message,
      valid,
      sending,
      statusMessage,
    } = this.state;
    return (
      <form onSubmit={this.onSubmit} onChange={this.onChangeInput}>
        <div className={css.formIntro}>
          Curious about simplifying your processes with our rapid DNA detection
          solutions? Drop us a line:
        </div>
        <div className={css.halfRow}>
          <div className={css.inputContainer}>
            <input
              type="text"
              name="firstname"
              aria-label="First Name"
              className={`${firstname ? css.dirty : ''} ${css.formInput}`}
              required
            />
            <span className={css.inputPlaceholder}>First Name</span>
          </div>
          <div className={css.inputContainer}>
            <input
              type="text"
              name="lastname"
              aria-label="Last Name"
              className={`${lastname ? css.dirty : ''} ${css.formInput}`}
              required
            />
            <span className={css.inputPlaceholder}>Last Name</span>
          </div>
        </div>
        <div className={css.inputContainer}>
          <input
            type="text"
            name="email"
            aria-label="Email"
            className={`${email ? css.dirty : ''} ${css.formInput}`}
            required
          />
          <span className={css.inputPlaceholder}>Email</span>
        </div>
        <div className={css.inputContainer}>
          <input
            type="text"
            name="phone"
            aria-label="Phone Number"
            className={`${phone ? css.dirty : ''} ${css.formInput}`}
          />
          <span className={css.inputPlaceholder}>Phone number</span>
        </div>
        <div className={css.inputContainer}>
          <input
            type="text"
            name="message"
            aria-label="Message"
            className={`${message ? css.dirty : ''} ${css.formInput}`}
          />
          <span className={css.inputPlaceholder}>Message</span>
        </div>
        <div className={css.inputContainer}>
          <input
            type="checkbox"
            name="privacy"
            aria-label="privacy"
            className={css.privacyInput}
            onChange={this.onChangeCheckbox}
          />
          <span className={css.privacyMessage}>
            I agree to allow SwissDeCode to store and process my personal data
            and receive marketing communications.
          </span>
        </div>
        <button
          type="submit"
          className={css.formBtn}
          disabled={!valid || sending}
        >
          {sending ? 'Sending...' : 'Submit'}
        </button>
        {statusMessage ? (
          <div className={css.formMessage}>{statusMessage}</div>
        ) : null}
      </form>
    );
  }
}

export default ContactForm;
