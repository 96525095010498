import React from 'react';
import { Link } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import Slider from 'react-slick';

import Button from 'components/Button';

import dnafoilLogo from 'images/dnafoil-logo-color.png';
import beamitupLogo from 'images/beamitup.png';

import css from './products-menu.module.scss';

const getCategoryLogo = categoryUid => {
  let image = null;
  switch (categoryUid) {
    case 'dna-foil':
      image = dnafoilLogo;
      break;
    case 'beamitup':
      image = beamitupLogo;
      break;
    default:
  }

  return image ? (
    <img src={image} className={css.typeMiniLogo} alt={categoryUid} />
  ) : null;
};

const ProductsMenu = ({ onSelect }) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
  };
  const data = useStaticQuery(graphql`
    query {
      prismic {
        allMenus {
          edges {
            node {
              products_intro
              products_items {
                products_item_description
                products_item_link {
                  ... on PRISMIC_Product {
                    _meta {
                      uid
                    }
                    product_category {
                      ... on PRISMIC_Product_category {
                        _meta {
                          uid
                        }
                      }
                    }
                  }
                }
                products_item_logo
                products_item_title
              }
            }
          }
        }
      }
    }
  `);

  const productMenu = data.prismic.allMenus.edges[0];
  if (!productMenu) return null;

  const products = productMenu.node.products_items
    .map(item => {
      const uid = item.products_item_link
        ? item.products_item_link._meta.uid
        : null;
      if (!uid) {
        return null;
      }

      const categoryId = item.products_item_link.product_category
        ? item.products_item_link.product_category._meta.uid
        : null;
      if (!categoryId) {
        return null;
      }

      return {
        uid,
        title: item.products_item_title,
        description: item.products_item_description,
        icon: item.products_item_logo,
        categoryId,
      };
    })
    .filter(product => product !== null);

  return (
    <>
      <div className={css.header}>
        <img src={dnafoilLogo} className={css.typeLogo} alt="DNAFoil" />
        <div className={css.title}>{productMenu.node.products_intro}</div>
        <Link to="/dna-foil" className={css.headerLink} onClick={onSelect}>
          <Button filled>Learn more</Button>
        </Link>
      </div>
      <Slider {...settings} className={css.list}>
        {products.map(child => (
          <div key={child.uid}>
            <Link
              to={`/${child.categoryId}/${child.uid}`}
              className={css.itemContent}
              onClick={onSelect}
            >
              <img
                className={css.itemImage}
                src={child.icon.url}
                alt={child.icon.alt}
              />
              <div className={css.itemInfo}>
                {getCategoryLogo(child.categoryId)}
                <h4 className={css.itemTitle}>{child.title}</h4>
                <p className={css.description}>
                  {RichText.asText(child.description)}
                </p>
              </div>
            </Link>
          </div>
        ))}
      </Slider>
    </>
  );
};

export default ProductsMenu;
