import React from 'react';

const TwitterShareIcon = ({ width, height, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    className={className}
    viewBox="0 0 20 17"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M19.893 2.394a8.3 8.3 0 01-2.357.636A4.063 4.063 0 0019.34.795a8.294 8.294 0 01-2.605.98A4.128 4.128 0 0013.74.5c-2.65 0-4.596 2.433-3.997 4.96a11.711 11.711 0 01-8.458-4.22 4.006 4.006 0 001.27 5.392 4.134 4.134 0 01-1.858-.505c-.045 1.871 1.317 3.621 3.29 4.011-.577.155-1.21.19-1.852.07.522 1.604 2.037 2.772 3.833 2.805a8.346 8.346 0 01-6.075 1.672 11.748 11.748 0 006.29 1.815c7.618 0 11.922-6.334 11.662-12.015a8.265 8.265 0 002.048-2.091z"
    />
  </svg>
);

export default TwitterShareIcon;
