import React, { useState } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';

import LinkArrowIcon from 'components/Icons/LinkArrowIcon';
import PhoneIcon from 'components/Icons/PhoneIcon';
import MailIcon from 'components/Icons/MailIcon';
import FacebookIcon from 'components/Icons/FacebookIcon';
import TwitterIcon from 'components/Icons/TwitterIcon';
import LinkedinIcon from 'components/Icons/LinkedinIcon';

import css from './mobile-nav.module.scss';

const products = [
  {
    node: {
      _meta: {
        uid: 'dna-foil',
      },
      menu_title: [
        {
          type: 'heading3',
          text: 'DNAFoil',
          spans: [],
        },
      ],
    },
  },
];

const MobileNav = ({ open, onSelect }) => {
  const data = useStaticQuery(graphql`
    query {
      prismic {
        allApplications(sortBy: menu_order_ASC) {
          edges {
            node {
              _meta {
                uid
              }
              menu_title
              menu_order
            }
          }
        }
        allProjects {
          edges {
            node {
              _meta {
                uid
              }
              menu_title: hero_title
            }
          }
        }
      }
    }
  `);
  const [selectedMenu, setSelectedMenu] = useState(null);

  const applications = data.prismic.allApplications.edges;
  const projects = data.prismic.allProjects.edges;
  const groups = [
    {
      title: 'Applications',
      slug: 'applications',
      children: applications,
    },
    {
      title: 'Products',
      slug: '',
      children: products,
    },
    {
      title: 'Projects',
      slug: 'projects',
      children: projects,
    },
    {
      title: 'Company',
      slug: 'company',
    },
  ];

  return (
    <div className={`${css.host} ${open ? css.opened : ''}`}>
      <div className={css.content}>
        {groups.map(group => {
          const expanded = selectedMenu === group.slug;
          return (
            <div
              key={group.title}
              className={`${css.navGroup}  ${expanded ? css.expanded : ''}`}
            >
              {group.children ? (
                <>
                  <div
                    className={css.groupHeader}
                    role="presentation"
                    onClick={() =>
                      setSelectedMenu(!expanded ? group.slug : null)
                    }
                  >
                    <h4 className={css.groupTitle}>{group.title}</h4>
                    <LinkArrowIcon
                      width={16}
                      height={16}
                      className={css.icon}
                    />
                  </div>
                  <ul className={css.groupList}>
                    {group.children.map(item => (
                      <li key={item.node._meta.uid}>
                        <Link
                          to={`${group.slug ? `/${group.slug}` : ''}/${
                            item.node._meta.uid
                          }`}
                          className={css.groupItem}
                          onClick={onSelect}
                        >
                          {RichText.asText(item.node.menu_title)}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <Link
                  to={`/${group.slug}`}
                  className={`${css.groupHeader} ${css.groupTitle}`}
                  onClick={onSelect}
                >
                  {group.title}
                </Link>
              )}
            </div>
          );
        })}
        <div className={css.navFooter}>
          <div>
            <div className={css.contactItem}>
              <PhoneIcon width={16} height={16} />
              <a href="tel:+41216244719">+41 21 624 47 19</a>
            </div>
            <div className={css.contactItem}>
              <MailIcon width={16} height={16} />
              <a href="mailto:hello@swissdecode.com">hello@swissdecode.com</a>
            </div>
          </div>
          <div className={css.footerSocial}>
            <a
              href="https://www.facebook.com/swissdecode"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookIcon width={24} height={24} />
            </a>
            <a
              href="https://twitter.com/swissdecode"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterIcon width={24} height={24} />
            </a>
            <a
              href="https://www.linkedin.com/company/swissdecode"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedinIcon width={24} height={24} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

MobileNav.propTypes = {
  open: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default MobileNav;
