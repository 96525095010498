import React from 'react';
import { Link } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import Slider from 'react-slick';

import css from './sub-menu.module.scss';

const SubMenu = ({ intro, items, slug, onSelect }) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
  };
  return (
    <>
      <div className={css.title}>{intro}</div>
      <Slider {...settings} className={css.list}>
        {items.map(item => (
          <li key={item.uid} className={css.item}>
            <Link
              to={`/${slug}/${item.uid}`}
              className={css.itemContent}
              onClick={onSelect}
            >
              {item.icon && (
                <img
                  src={item.icon.url}
                  className={css.itemLogo}
                  alt={item.icon.alt}
                />
              )}
              <div className={css.itemInfo}>
                <h4 className={css.itemTitle}>{item.title}</h4>
                <p className={css.itemDescription}>
                  {RichText.asText(item.description)}
                </p>
              </div>
            </Link>
          </li>
        ))}
      </Slider>
    </>
  );
};

export default SubMenu;
