import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import Hero from 'components/Hero';
import { resolveSlice } from 'utils/slice-resolver.js';
import LanguageSwitcher from 'components/LanguageSwitcher';

import css from './a2-inside.module.scss';
import shared from 'styles/shared.module.scss';

const ExternalPage = ({ data: { prismic }, location }) => {
  const siteUrl = 'www.a2inside.com';

  const content = prismic.allExternal_pages.edges[0];
  if (!content) return null;

  const page = content.node;
  const image = page.hero_imageSharp;
  const slices = page.body || [];
  const socialImage = page.hero_image ? page.hero_image.url : '';

  return (
    <>
      <Helmet>
        <title>{`A2 Inside Label`}</title>
        <meta property="og:title" content="A2 Inside Label" />
        <meta property="og:url" content={`${siteUrl}${location.pathname}`} />
        <meta property="og:image" content={socialImage} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="A2 Inside Label" />
        <meta name="twitter:image" content={socialImage} />
        <link rel="canonical" href={`${siteUrl}${location.pathname}`} />
      </Helmet>
      <Hero
        image={image ? image.childImageSharp.fluid : null}
        logo={page.hero_logo ? page.hero_logo.url : ''}
        title={page.hero_title}
        description={page.hero_description}
      />
      <LanguageSwitcher
        className={css.switcher}
        urls={[{ lang: 'en', url: '/' }, { lang: 'jp', url: '/jp' }]}
      />
      <section className={`${css.introBox} ${shared.maxwidth}`}>
        <div className={css.introBoxContent}>
          <h4 className={shared.sectionType}>{page.highlight_title}</h4>
          <h2 className={css.introTitle}>{page.highlight_text}</h2>
        </div>
      </section>
      {slices.map(resolveSlice)}
    </>
  );
};

export const pageQuery = graphql`
  query PagesByUid($lang: String) {
    prismic {
      allExternal_pages(lang: $lang) {
        edges {
          node {
            highlight_title
            highlight_text
            hero_logo
            hero_image
            hero_imageSharp {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            hero_title
            hero_description
            body {
              ... on PRISMIC_External_pageBodyContent_section {
                primary {
                  title
                  type
                }
                fields {
                  main
                  icon
                  content_text
                  image
                  imageSharp {
                    childImageSharp {
                      fluid(quality: 90, maxWidth: 800) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                type
              }
              ... on PRISMIC_External_pageBodyContact_section {
                type
                primary {
                  button_label
                  title
                  type
                  inverted
                  hubspot_portal_id
                  hubspot_form_id
                }
              }
              ... on PRISMIC_External_pageBodyFaq_section {
                type
                fields {
                  answer
                  question
                }
                faq_primary: primary {
                  label
                  title
                }
              }
              ... on PRISMIC_External_pageBodyPartners_section {
                type
                label
                primary {
                  description
                  title
                  transparent
                }
                fields {
                  logo
                  logo_url {
                    ... on PRISMIC_A2_inside_customer {
                      _meta {
                        uid
                        lang
                      }
                    }
                  }
                }
              }
            }
            _meta {
              alternateLanguages {
                uid
                lang
              }
            }
          }
        }
      }
    }
  }
`;

export default ExternalPage;
