import React from 'react';
import { RichText } from 'prismic-reactjs';
import classnames from 'classnames';

import css from './video-section.module.scss';
import shared from 'styles/shared.module.scss';

const VideoSection = ({ label, title, description, video, videoClassName }) => {
  return (
    <section className={`${css.host} ${shared.maxwidth}`}>
      <div className={css.background} />
      <div className={css.content}>
        <div className={css.left}>
          <h4 className={shared.sectionType}>{RichText.asText(label)}</h4>
          <h2 className={shared.sectionTitle}>{RichText.asText(title)}</h2>
          <div className={css.description}>
            <RichText render={description} />
          </div>
        </div>
        <div className={classnames(css.video, videoClassName)}>
          <iframe
            title="Video"
            width="100%"
            height="100%"
            src={video.url}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default VideoSection;
