import React from 'react';
import Helmet from 'react-helmet';
import Header from './Header';
import Footer from './Footer';
import Banner from './Banner';
import GlobalPartners from './GlobalPartners';

import css from './layout.module.scss';

export default ({ page, children }) => (
  <>
    <Helmet>
      <html lang="en" />
      <title>SwissDeCode</title>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />
      <meta description="SwissDeCode helps farmers and food manufacturers to grow and produce food that is safe to eat by providing rapid food safety solutions." />
      <meta property="og:type" content="website" />
      <meta
        name="google-site-verification"
        content="MkF85JqMcFKP-TpMh00c7zSVIMbEddemB5lzo2O0eas"
      />
    </Helmet>
    <Header activePage={page} />
    <main className={css.main}>
      <Banner />
      {children}
      <GlobalPartners />
    </main>
    <Footer />
  </>
);
