import React from 'react';
import Img from 'gatsby-image';

import ArrowIcon from 'components/Icons/ArrowIcon';

import css from './bullet-points-section.module.scss';
import shared from 'styles/shared.module.scss';

const BulletPointsSection = ({ label, title, image, points }) => (
  <section className={`${css.pointsSection} ${shared.maxwidth}`}>
    <div className={css.pointsBkg} />
    <div className={css.pointsContainer}>
      <div className={css.pointsImageWrapper}>
        {image ? (
          <Img
            fluid={image.childImageSharp.fluid}
            className={css.pointsImage}
          />
        ) : null}
      </div>
      <div className={css.pointsContent}>
        <h4 className={shared.sectionType}>{label}</h4>
        <h2 className={shared.sectionTitle}>{title}</h2>
        <ul className={css.pointsList}>
          {points.map((point, idx) => (
            <li key={idx} className={css.pointsItem}>
              <ArrowIcon className={css.arrowIcon} />
              <div className={css.pointsInfo}>{point}</div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </section>
);

export default BulletPointsSection;
