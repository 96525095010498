import React from 'react';
import { RichText } from 'prismic-reactjs';
import { Link } from 'gatsby';
import Button from 'components/Button';

import css from './solutions-section.module.scss';
import shared from 'styles/shared.module.scss';

const SolutionsSection = ({
  type,
  title,
  solutions,
  otherTitle,
  otherDescription,
  contactLabel,
}) => {
  return (
    <section className={css.host}>
      <div className={shared.maxwidth}>
        <h4 className={shared.sectionType}>{RichText.asText(type)}</h4>
        <h3 className={shared.sectionTitle}>{RichText.asText(title)}</h3>
        <ul className={css.solutionList}>
          {solutions.map((solution, idx) => {
            const parentElem = solution.link ? (
              <Link to={solution.link} />
            ) : (
              <li />
            );
            return (
              <parentElem.type
                key={idx}
                className={css.solutionItem}
                {...parentElem.props}
              >
                {solution.image ? (
                  <img
                    src={solution.image}
                    className={css.solutionImage}
                    alt={solution.name}
                  />
                ) : null}
                <h4 className={css.solutionTitle}>{solution.name}</h4>
              </parentElem.type>
            );
          })}
        </ul>
        {otherTitle ? (
          <div className={css.solutionOther}>
            <h4 className={shared.sectionTitle}>{otherTitle}</h4>
            <p className={shared.sectionDescription}>{otherDescription} </p>
            <a href="#contact-us">
              <Button className={css.solutionBtn}>{contactLabel}</Button>
            </a>
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default SolutionsSection;
