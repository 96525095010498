import React from 'react';

const FacebookShareIcon = ({ width, height, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    className={className}
    viewBox="0 0 16 17"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M16 .5v16h-5.333v-5.333h2.666V8.5h-2.666V6.536c0-1.37 2.666-.703 2.666-.703V3.167c-2.666 0-3.38.056-4 .666C8.714 4.443 8 5.43 8 6.536V8.5H5.333v2.667H8s-.15 3.22 0 5.333H0V.5h16z"
    />
  </svg>
);

export default FacebookShareIcon;

