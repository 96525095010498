import React from 'react';
import { RichText } from 'prismic-reactjs';
import { Link } from 'gatsby';

import LinkArrowIcon from 'components/Icons/LinkArrowIcon';
import { linkResolver } from 'utils/link-resolver';

import css from './children-section.module.scss';
import shared from 'styles/shared.module.scss';

const ChildrenSection = ({ label, title, childArray }) => {
  return (
    <section className={css.host}>
      <div className={shared.maxwidth}>
        <h4 className={shared.sectionType}>{RichText.asText(label)}</h4>
        <h2 className={shared.sectionTitle}>{RichText.asText(title)}</h2>
        <ul className={css.list}>
          {childArray.map((child, idx) => {
            return (
              <li key={idx} className={css.item}>
                <img className={css.logo} src={child.image} alt="Logo" />
                <h3 className={css.name}>{RichText.asText(child.title)}</h3>
                <p className={css.description}>
                  {RichText.asText(child.description)}
                </p>
                {child.link ? (
                  <Link to={linkResolver(child.link)} className={css.link}>
                    <span>Learn more</span>
                    <LinkArrowIcon width={7} height={10} />
                  </Link>
                ) : null}
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};

export default ChildrenSection;
