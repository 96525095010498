import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import SubMenu from 'components/SubMenu';

const ServicesMenu = ({ onSelect, mobile }) => {
  const data = useStaticQuery(graphql`
    query {
      prismic {
        allMenus {
          edges {
            node {
              services_intro
              services_items {
                services_item_description
                services_item_link {
                  ... on PRISMIC_Service {
                    _meta {
                      uid
                    }
                  }
                }
                services_item_logo
                services_item_title
              }
            }
          }
        }
      }
    }
  `);

  const serviceMenu = data.prismic.allMenus.edges[0];
  if (!serviceMenu) return null;

  const services = serviceMenu.node.services_items.map(item => ({
    uid: item.services_item_link ? item.services_item_link._meta.uid : null,
    title: item.services_item_title,
    description: item.services_item_description,
    icon: item.services_item_logo,
  }));

  return (
    <SubMenu
      intro={serviceMenu.node.services_intro}
      items={services}
      slug="services"
      onSelect={onSelect}
      mobile={mobile}
    />
  );
};

export default ServicesMenu;
