import React from 'react';

export default ({ width, height, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    className={className}
    viewBox="0 0 7 10"
  >
    <path
      fill="none"
      stroke="#C83E3E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M1 1l4.23 4L1 9"
    />
  </svg>
);
