import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import classnames from 'classnames';
import { RichText } from 'prismic-reactjs';
import { Link } from 'gatsby';

import Button from 'components/Button';
import placeholderImage from 'images/placeholder.jpg';

import css from './content-section.module.scss';
import shared from 'styles/shared.module.scss';

const renderSingleSection = (section, idx, reversed, keepLeft) => {
  let className = '';
  if (!keepLeft) {
    className =
      idx % 2 !== 0 ? (!reversed ? css.even : '') : !reversed ? '' : css.even;
  }
  return (
    <div key={idx} className={`${css.content} ${className}`}>
      {section.image ? (
        <Img
          className={css.image}
          fluid={section.image}
          alt={section.imageAlt || ''}
          title={section.imageAlt || ''}
        />
      ) : (
        <img className={css.image} src={placeholderImage} alt="placeholder" />
      )}
      <div className={css.info}>
        {section.logo && section.logo.url && (
          <img
            src={section.logo.url}
            className={css.logo}
            alt={section.logo.alt}
          />
        )}
        {section.date ? <div className={css.date}>{section.date}</div> : null}
        <h2 className={css.title}>{RichText.asText(section.title)}</h2>
        <div className={css.description}>
          {section.description && <RichText render={section.description} />}
          {section.clickableUrl ? (
            <Link to={section.clickableUrl} className={css.clickableLink}>
              Read more
            </Link>
          ) : null}
        </div>
        {section.link && section.link.same && (
          <Link to={section.link.url}>
            <Button className={css.btn} filled>
              {section.link.label}
            </Button>
          </Link>
        )}
        {section.link && !section.link.same && (
          <a href={section.link.url} target="_blank" rel="noopener noreferrer">
            <Button className={css.btn} filled>
              {section.link.label}
            </Button>
          </a>
        )}
      </div>
    </div>
  );
};

const renderMultipleSection = (section, idx) => (
  <div key={idx} className={`${css.content} ${css.triple}`}>
    <h2 className={css.title}>{RichText.asText(section.title)}</h2>
    <div className={css.description}>
      <RichText render={section.description} />
    </div>
    <div className={css.images}>
      <img
        className={css.image}
        src={section.image.url}
        alt={section.image.alt}
      />
      <img
        className={css.image}
        src={section.secondary_image.url}
        alt={section.secondary_image.alt}
      />
      <img
        className={css.image}
        src={section.tertiary_image.url}
        alt={section.tertiary_image.alt}
      />
    </div>
  </div>
);

const ContentSection = ({
  type,
  title,
  alignment,
  sections,
  reversed,
  keepLeft,
}) => {
  const typeLength = Array.isArray(type)
    ? type.reduce((acc, val) => acc + val.text.length, 0)
    : 0;
  const titleLength = Array.isArray(title)
    ? title.reduce((acc, val) => acc + val.text.length, 0)
    : 0;

  const hasHeaders = typeLength + titleLength > 0;
  return (
    <section className={`${css.host} ${shared.maxwidth}`}>
      {hasHeaders ? (
        <div
          className={classnames(css.header, {
            [css.leftAlign]: alignment === 'left',
            [css.rightAlign]: alignment === 'right',
          })}
        >
          {typeLength > 0 && (
            <h4 className={shared.sectionType}>{RichText.asText(type)}</h4>
          )}
          {titleLength > 0 && (
            <h3 className={shared.sectionTitle}>{RichText.asText(title)}</h3>
          )}
        </div>
      ) : null}
      {sections.map((section, idx) =>
        !section.secondary_image ||
        !section.secondary_image.url ||
        !section.tertiary_image ||
        !section.tertiary_image.url
          ? renderSingleSection(section, idx, reversed, keepLeft)
          : renderMultipleSection(section, idx)
      )}
    </section>
  );
};

ContentSection.propTypes = {
  type: PropTypes.array,
  title: PropTypes.array,
  sections: PropTypes.array,
};

ContentSection.defaultProps = {
  type: [],
  title: [],
  sections: [],
};

export default ContentSection;
