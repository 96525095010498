import React from 'react';
import Helmet from 'react-helmet';
import Footer from '../Footer';

export default ({ page, hasFooter = true, children }) => (
  <>
    <Helmet>
      <html lang="en" />
      <title>A2 Inside Label</title>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/a2inside/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/a2inside/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/a2inside/favicon-16x16.png"
      />
      <link rel="manifest" href="/a2inside/site.webmanifest" />
      <link
        rel="mask-icon"
        href="/a2inside/safari-pinned-tab.svg"
        color="#5bbad5"
      />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />
      <meta property="og:type" content="website" />
      <meta
        name="google-site-verification"
        content="MkF85JqMcFKP-TpMh00c7zSVIMbEddemB5lzo2O0eas"
      />
    </Helmet>
    <main>{children}</main>
    {hasFooter && <Footer minimal />}
  </>
);
