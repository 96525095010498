import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import classnames from 'classnames';
import { RichText } from 'prismic-reactjs';

import Button from 'components/Button';

import css from './banner.module.scss';
import shared from 'styles/shared.module.scss';

const Banner = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      prismic {
        allBanners {
          edges {
            node {
              image
              badge_text
              title
              description
              button_label
              button_link {
                ... on PRISMIC_Product {
                  _meta {
                    uid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const url = typeof window !== 'undefined' ? window.location.href : '';

  const bannerEdge = data.prismic.allBanners.edges[0];
  if (!bannerEdge) return null;

  const banner = bannerEdge.node;

  if (url.includes(banner.button_link._meta.uid)) return null;

  return (
    <div className={css.host}>
      <div className={classnames(css.content, shared.maxwidth)}>
        {banner.image ? (
          <img
            src={banner.image.url}
            className={css.image}
            alt={banner.image.alt}
          />
        ) : null}
        <div>
          <div className={css.titleWrapper}>
            <div className={css.badge}>{banner.badge_text}</div>
            <div className={css.title}>{banner.title}</div>
            <div className={css.actionMobile}>
              <Link to={`/dna-foil/${banner.button_link._meta.uid}`}>
                {banner.button_label}
              </Link>
            </div>
          </div>
          <div className={css.description}>
            <RichText render={banner.description} />
          </div>
        </div>
        <div className={css.actions}>
          <Link to={`/dna-foil/${banner.button_link._meta.uid}`}>
            <Button className={css.button} filled>
              {banner.button_label}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Banner;
