import React, { useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

import PhoneIcon from 'components/Icons/PhoneIcon';
import MailIcon from 'components/Icons/MailIcon';
import FacebookIcon from 'components/Icons/FacebookIcon';
import TwitterIcon from 'components/Icons/TwitterIcon';
import LinkedinIcon from 'components/Icons/LinkedinIcon';
import ApplicationsMenu from 'components/ApplicationsMenu';
import ProductsMenu from 'components/ProductsMenu';
import ServicesMenu from 'components/ServicesMenu';
import MobileNav from 'components/MobileNav';

import logo from 'images/logo.png';

import css from './header.module.scss';
import shared from 'styles/shared.module.scss';
import ProjectsMenu from '../ProjectsMenu';

const Header = ({ activePage }) => {
  const data = useStaticQuery(graphql`
    query {
      prismic {
        allMenus {
          edges {
            node {
              applications_title
              applications_visible
              company_title
              company_visible
              blog_visible
              blog_title
              services_title
              services_visible
              projects_title
              projects_visible
              products_title
              products_visible
            }
          }
        }
      }
    }
  `);

  const [activeMenu, setActiveMenu] = useState(null);
  const [isMobileOpen, toggleMenuOpen] = useState(false);

  const menu = data.prismic.allMenus.edges[0];
  let menuItems = {};
  if (menu) {
    menuItems = menu.node;
  }

  return (
    <header
      className={css.host}
      role="presentation"
      onMouseLeave={() => setActiveMenu(null)}
    >
      <div className={css.headerTop}>
        <div className={`${css.headerTopContent} ${shared.maxwidth}`}>
          <div
            className={css.contacts}
            role="presentation"
            onMouseEnter={() => setActiveMenu(null)}
          >
            <div className={css.contactItem}>
              <PhoneIcon width={16} height={16} />
              <a href="tel:+41216244719">+41 21 624 47 19</a>
            </div>
            <div className={css.contactItem}>
              <MailIcon width={16} height={16} />
              <a href="mailto:hello@swissdecode.com">hello@swissdecode.com</a>
            </div>
          </div>
          <div
            className={css.socialList}
            role="presentation"
            onMouseEnter={() => setActiveMenu(null)}
          >
            <a
              href="https://www.facebook.com/swissdecode"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookIcon width={24} height={24} />
            </a>
            <a
              href="https://twitter.com/swissdecode"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterIcon width={24} height={24} />
            </a>
            <a
              href="https://www.linkedin.com/company/swissdecode"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedinIcon width={24} height={24} />
            </a>
          </div>
        </div>
      </div>
      <div className={css.headerBottom}>
        <div className={`${css.headerBottomContent} ${shared.maxwidth}`}>
          <Link
            to="/"
            onMouseEnter={() => setActiveMenu(null)}
            onClick={() => toggleMenuOpen(false)}
          >
            <img className={css.logo} src={logo} alt="logo" />
          </Link>
          <nav className={css.nav}>
            <ul className={css.navMenu}>
              {menuItems.applications_visible ? (
                <li
                  className={`${css.navItem} ${
                    activePage === 'applications' ? css.selected : ''
                  }`}
                  role="presentation"
                  onMouseEnter={() => setActiveMenu('applications')}
                >
                  {menuItems.applications_title}
                </li>
              ) : null}
              {menuItems.products_visible ? (
                <li
                  className={`${css.navItem} ${
                    activePage === 'products' ? css.selected : ''
                  }`}
                  role="presentation"
                  onMouseEnter={() => setActiveMenu('products')}
                >
                  {menuItems.products_title}
                </li>
              ) : null}
              {menuItems.services_visible ? (
                <li
                  className={`${css.navItem} ${
                    activePage === 'services' ? css.selected : ''
                  }`}
                  role="presentation"
                  onMouseEnter={() => setActiveMenu('services')}
                >
                  {menuItems.services_title}
                </li>
              ) : null}
              {menuItems.projects_visible ? (
                <li
                  className={`${css.navItem} ${
                    activePage === 'projects' ? css.selected : ''
                  }`}
                  role="presentation"
                  onMouseEnter={() => setActiveMenu('projects')}
                >
                  {menuItems.projects_title}
                </li>
              ) : null}
              {menuItems.company_visible ? (
                <li
                  className={`${css.navItem} ${
                    activePage === 'company' ? css.selected : ''
                  }`}
                  role="presentation"
                  onMouseEnter={() => setActiveMenu(null)}
                >
                  <Link to="/company" onClick={() => setActiveMenu(null)}>
                    {menuItems.company_title}
                  </Link>
                </li>
              ) : null}
              {menuItems.blog_visible ? (
                <li
                  className={`${css.navItem} ${
                    activePage === 'blog' ? css.selected : ''
                  }`}
                  role="presentation"
                  onMouseEnter={() => setActiveMenu(null)}
                >
                  <Link to="/blog" onClick={() => setActiveMenu(null)}>
                    {menuItems.blog_title}
                  </Link>
                </li>
              ) : null}
            </ul>
          </nav>
          <button
            className={`${css.navBtnMobile} ${
              isMobileOpen ? css.mobileOpen : ''
            }`}
            onClick={() => toggleMenuOpen(!isMobileOpen)}
          >
            <div className={css.line} />
            <div className={css.line} />
            <div className={css.line} />
          </button>
        </div>
      </div>
      <MobileNav open={isMobileOpen} onSelect={() => toggleMenuOpen(false)} />
      <div className={css.submenus}>
        <div
          className={`${css.submenu} ${
            activeMenu === 'applications' ? css.active : ''
          }`}
        >
          <div className={shared.maxwidth}>
            <ApplicationsMenu onSelect={() => setActiveMenu(null)} />
          </div>
        </div>
        <div
          className={`${css.submenu} ${
            activeMenu === 'products' ? css.active : ''
          }`}
        >
          <div className={shared.maxwidth}>
            <ProductsMenu onSelect={() => setActiveMenu(null)} />
          </div>
        </div>
        <div
          className={`${css.submenu} ${
            activeMenu === 'services' ? css.active : ''
          }`}
        >
          <div className={shared.maxwidth}>
            <ServicesMenu onSelect={() => setActiveMenu(null)} />
          </div>
        </div>
        <div
          className={`${css.submenu} ${
            activeMenu === 'projects' ? css.active : ''
          }`}
        >
          <div className={shared.maxwidth}>
            <ProjectsMenu onSelect={() => setActiveMenu(null)} />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
