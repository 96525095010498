import React from 'react';
import Slider from 'react-slick';
import { useStaticQuery, graphql } from 'gatsby';

import css from './global-partners.module.scss';
import shared from 'styles/shared.module.scss';

const GlobalPartners = () => {
  const data = useStaticQuery(graphql`
    query {
      prismic {
        company(uid: "company", lang: "pt-pt") {
          partners_title
          partners_intro
          partners_logos {
            image
            imageSharp {
              childImageSharp {
                fluid(quality: 90, maxWidth: 160) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `);

  const { company } = data.prismic;
  const partners = company.partners_logos || [];

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: partners.length < 3 ? partners.length : 3,
    slidesToScroll: 1,
    dots: true,
    autoplay: true,
  };

  return (
    <section className={css.partnersSection}>
      <div className={shared.maxwidth}>
        <h2 className={css.partnersTitle}>{company.partners_title}</h2>
        <Slider {...settings} className={css.partnersList}>
          {(company.partners_logos || []).map((logo, idx) => (
            <li key={idx} className={css.partnersItem}>
              <img src={logo.image.url} alt={logo.image.alt} />
            </li>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default GlobalPartners;
