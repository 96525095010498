import React from 'react';
import { RichText } from 'prismic-reactjs';
import classnames from 'classnames';
import { Link } from 'gatsby';

import css from './partners-section.module.scss';
import shared from 'styles/shared.module.scss';

const PartnersSection = ({ title, description, transparent, logos }) => {
  const getDocumentUrl = link => {
    const { _meta } = link;
    const languageCode = _meta.lang ? _meta.lang.split('-')[1] : '';
    return `${languageCode !== 'pt' ? `/${languageCode}` : ''}/${_meta.uid}`;
  };
  const hasDescription = description && description.length > 0;
  return (
    <section
      className={classnames(css.partnersSection, {
        [css.transparent]: transparent,
      })}
    >
      <div className={shared.maxwidth}>
        <h2 className={css.partnersTitle}>{RichText.asText(title)}</h2>
        <p className={css.partnersDescription}>
          {hasDescription ? RichText.asText(description) : ''}
        </p>
        <ul className={css.partnersList}>
          {(logos || []).map((logo, idx) =>
            logo.link ? (
              <Link
                to={getDocumentUrl(logo.link)}
                key={idx}
                className={css.partnersItem}
              >
                <img src={logo.image} alt={logo.name} />
              </Link>
            ) : (
              <li key={idx} className={css.partnersItem}>
                <img src={logo.image} alt={logo.name} />
              </li>
            )
          )}
        </ul>
      </div>
    </section>
  );
};

export default PartnersSection;
