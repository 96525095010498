import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { RichText } from 'prismic-reactjs';

import ContactForm from 'components/ContactForm';
import ArrowIcon from 'components/Icons/ArrowIcon';
import LocationIcon from 'components/Icons/LocationIcon';
import PhoneIcon from 'components/Icons/PhoneIcon';
import MailIcon from 'components/Icons/MailIcon';
import FacebookIcon from 'components/Icons/FacebookIcon';
import TwitterIcon from 'components/Icons/TwitterIcon';
import LinkedinIcon from 'components/Icons/LinkedinIcon';
import FacebookShareIcon from 'components/Icons/FacebookShareIcon';
import TwitterShareIcon from 'components/Icons/TwitterShareIcon';
import LinkedinShareIcon from 'components/Icons/LinkedinShareIcon';

import SharePopup from 'utils/share';

import logoWhite from 'images/logo_white.png';
import badge from 'images/footer_badge.png';

import css from './footer.module.scss';
import shared from 'styles/shared.module.scss';

const Footer = ({ minimal }) => {
  const data = useStaticQuery(graphql`
    query {
      prismic {
        allApplications(sortBy: menu_order_ASC) {
          edges {
            node {
              _meta {
                uid
              }
              menu_title
            }
          }
        }
        allProjects {
          edges {
            node {
              _meta {
                uid
              }
              hero_title
            }
          }
        }
      }
    }
  `);

  const applications = data.prismic.allApplications.edges;
  const projects = data.prismic.allProjects.edges;

  return (
    <footer className={css.host}>
      {!minimal && (
        <div className={css.footerTop}>
          <div className={`${css.footerTopContent} ${shared.maxwidth}`}>
            <div className={css.footerColumn}>
              <img className={css.logoWhite} src={logoWhite} alt="logo" />
              <h4 className={css.footerTitle}>Share our page</h4>
              <p className={css.footerText}>
                Did this make you think of someone who would be interested in
                our products or services?
              </p>
              <p className={css.footerText}>Share this page with them!</p>
              <div className={css.footerShare}>
                <button
                  type="button"
                  className={css.facebookShare}
                  onClick={() => SharePopup.facebook()}
                >
                  <FacebookShareIcon width={16} height={16} />
                </button>
                <button
                  type="button"
                  className={css.twitterShare}
                  onClick={() => SharePopup.twitter()}
                >
                  <TwitterShareIcon width={20} height={16} />
                </button>
                <button
                  type="button"
                  className={css.linkedinShare}
                  onClick={() => SharePopup.linkedin()}
                >
                  <LinkedinShareIcon width={16} height={16} />
                </button>
              </div>
            </div>
            <div className={css.footerColumn}>
              <h4 className={css.footerTitle}>Quick links</h4>
              <div className={css.linksContainer}>
                <div className={css.linksTitle}>Applications</div>
                <ul className={css.linksList}>
                  {applications.map(application => (
                    <li key={application.node._meta.uid} className={css.item}>
                      <Link
                        to={`/applications/${application.node._meta.uid}`}
                        className={css.linksItem}
                      >
                        <ArrowIcon width={4} height={8} className={css.icon} />
                        <span>
                          {RichText.asText(application.node.menu_title)}
                        </span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className={css.linksContainer}>
                <div className={css.linksTitle}>Products</div>
                <ul className={css.linksList}>
                  <li className={css.item}>
                    <Link to={`/dna-foil`} className={css.linksItem}>
                      <ArrowIcon width={4} height={8} className={css.icon} />
                      <span>DNAFoil</span>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className={css.linksContainer}>
                <div className={css.linksTitle}>Projects</div>
                <ul className={css.linksList}>
                  {projects.map(project => (
                    <li key={project.node._meta.uid} className={css.item}>
                      <Link
                        to={`/projects/${project.node._meta.uid}`}
                        className={css.linksItem}
                      >
                        <ArrowIcon width={4} height={8} className={css.icon} />
                        <span>{RichText.asText(project.node.hero_title)}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className={css.linksContainer}>
                <Link to={`/company`} className={css.linksTitle}>
                  Company
                </Link>
              </div>
              <div className={css.linksContainer}>
                <Link to={`/press`} className={css.linksTitle}>
                  Press
                </Link>
              </div>
            </div>
            <div className={css.footerColumn}>
              <h4 className={css.footerTitle} id="contact-us">
                Contact form
              </h4>
              <ContactForm />
            </div>
          </div>
        </div>
      )}
      <div className={css.footerBottom}>
        <div className={`${css.footerBottomContent} ${shared.maxwidth}`}>
          <div className={`${css.footerColumn} ${css.location}`}>
            <LocationIcon width={16} height={16} />
            <div className={css.footerText}>
              SwissDeCode SA
              <br />
              Route de Chavannes 37
              <br />
              1007 Lausanne, VD
              <br />
              Switzerland
            </div>
          </div>
          <div className={`${css.footerColumn} ${css.contacts}`}>
            <div className={css.contact}>
              <PhoneIcon width={16} height={16} />
              <a href="tel:+41216244719">+41 21 624 47 19</a>
            </div>
            <div className={css.contact}>
              <MailIcon width={16} height={16} />
              <a href="mailto:hello@swissdecode.com">hello@swissdecode.com</a>
            </div>
          </div>
          <div className={css.footerColumn}>
            <div className={css.footerSocial}>
              <a
                href="https://www.facebook.com/swissdecode"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon width={28} height={28} />
              </a>
              <a
                href="https://twitter.com/swissdecode"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterIcon width={28} height={28} />
              </a>
              <a
                href="https://www.linkedin.com/company/swissdecode"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedinIcon width={28} height={28} />
              </a>
            </div>
            {!minimal && (
              <img
                src={badge}
                className={css.badge}
                alt="Rising Food Starts badge"
              />
            )}
          </div>
        </div>
      </div>
      <div className={css.privacy}>
        <div className={`${css.privacyContent} ${shared.maxwidth}`}>
          <span>© 2021 All rights reserved. SwissDeCode SA</span>
          {!minimal && (
            <div className={css.privacyLinks}>
              <Link to="/terms-conditions">{'Terms & Conditions'}</Link>
              <Link to="/privacy-policy">Privacy Policy</Link>
              <Link to="/credits">Credits</Link>
            </div>
          )}
        </div>
      </div>
    </footer>
  );
};
export default Footer;
