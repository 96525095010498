import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import { RichText } from 'prismic-reactjs';

import HeroShape from '../Shapes/HeroShape';

import css from './hero.module.scss';
import shared from 'styles/shared.module.scss';

const Hero = ({
  image,
  imageClassName,
  logo,
  logoClassName,
  title,
  subtitle,
  subtitleClassName,
  description,
  button,
}) => {
  const content = (
    <div className={shared.maxwidth}>
      <div className={css.container}>
        <HeroShape
          className={css.shape}
          topColor="#c83e3e"
          bottomColor="#d54545"
        />
        <div className={css.content}>
          {logo && (
            <img
              className={`${css.logo} ${logoClassName}`}
              src={logo}
              alt="Logo"
            />
          )}
          {title ? (
            <div className={`${css.title} ${subtitle ? css.withSubtitle : ''}`}>
              <RichText render={title} />
            </div>
          ) : null}
          {subtitle && (
            <div className={`${css.subtitle} ${subtitleClassName}`}>
              <RichText render={subtitle} />
            </div>
          )}
          <div className={css.description}>
            <RichText render={description} />
          </div>
          {button && (
            <a className={css.anchor} href={button.anchor}>
              {button.label}
            </a>
          )}
        </div>
      </div>
      <div className={css.mobileGradient} />
    </div>
  );

  return image ? (
    <BackgroundImage
      Tag="section"
      className={`${css.host} ${imageClassName}`}
      fluid={image}
    >
      {content}
    </BackgroundImage>
  ) : (
    <section className={css.host}>{content}</section>
  );
};

export default Hero;
