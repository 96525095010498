import React from 'react';

const HeroShape = ({ className, topColor, bottomColor }) => (
  <svg
    className={className}
    height="100%"
    width="100%"
    viewBox="0 0 760 560"
    preserveAspectRatio="none"
  >
    <defs>
      <linearGradient id="hero-gradient" x2="1" y2="1">
        <stop offset="0%" stopColor={topColor} />
        <stop offset="100%" stopColor={bottomColor} />
      </linearGradient>
    </defs>
    <path d="M60 0 L760 0 L700 560 L0 560 Z" fill="url(#hero-gradient)"/>
  </svg>
);

export default HeroShape;
