// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-external-pages-404-js": () => import("./../src/external-pages/404.js" /* webpackChunkName: "component---src-external-pages-404-js" */),
  "component---src-templates-a-2-inside-customer-js": () => import("./../src/templates/a2-inside-customer.js" /* webpackChunkName: "component---src-templates-a-2-inside-customer-js" */),
  "component---src-templates-a-2-inside-js": () => import("./../src/templates/a2-inside.js" /* webpackChunkName: "component---src-templates-a-2-inside-js" */)
}

