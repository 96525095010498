import React from 'react';

import css from './button.module.scss';

const Button = ({ className, filled, type, onClick, children }) => (
  <button
    type={type || 'button'}
    className={`${css.host} ${className} ${filled ? css.filled : ''}`}
    onClick={onClick}
  >
    {children}
  </button>
);

export default Button;
