import React from 'react';
import { RichText } from 'prismic-reactjs';

import css from './grid-section.module.scss';
import shared from 'styles/shared.module.scss';

const GridSection = ({ label, title, columns }) => {
  const orderedColumns = [[], [], []];
  columns.forEach(column => {
    const numIdx = Number(column.idx) - 1;
    if (orderedColumns[numIdx]) {
      orderedColumns[numIdx].push(column);
    }
  });

  return (
    <section className={`${css.host} ${shared.maxwidth}`}>
      <h4 className={shared.sectionType}>{RichText.asText(label)}</h4>
      <h2 className={shared.sectionTitle}>{RichText.asText(title)}</h2>
      <div className={css.boxList}>
        {orderedColumns.map((column, cIdx) => (
          <div key={cIdx} className={css.boxColumn}>
            {column.map((box, idx) => (
              <div key={idx} className={css.boxItem}>
                <h3 className={css.boxTitle}>{RichText.asText(box.title)}</h3>
                <h4 className={css.boxSubtitle}>
                  {RichText.asText(box.subtitle)}
                </h4>
                <p className={css.boxDescription}>
                  {RichText.asText(box.description)}
                </p>
              </div>
            ))}
          </div>
        ))}
      </div>
    </section>
  );
};

export default GridSection;
