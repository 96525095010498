import React from 'react';

const TwitterIcon = ({ width, height }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 26 26">
    <g fill="none" fillRule="evenodd" transform="translate(1 1)">
      <circle cx="12" cy="12" r="12" stroke="#C83E3E" />
      <path fill="#C83E3E" d="M18 8.652c-.439.194-.913.33-1.414.391a2.48 2.48 0 0 0 1.087-1.367c-.479.29-1.001.49-1.567.601a2.464 2.464 0 0 0-4.192 2.248A7.015 7.015 0 0 1 6.835 7.95c-.213.362-.333.79-.333 1.234 0 .858.436 1.615 1.095 2.05a2.327 2.327 0 0 1-1.113-.308v.031c0 1.196.852 2.189 1.973 2.415a2.47 2.47 0 0 1-.652.083c-.155 0-.305-.009-.46-.04a2.473 2.473 0 0 0 2.298 1.708 4.935 4.935 0 0 1-3.056 1.05c-.2 0-.393-.014-.588-.031a6.991 6.991 0 0 0 3.775 1.106c4.526 0 7.002-3.75 7.002-7.002l-.004-.316c.476-.354.9-.784 1.227-1.278" />
    </g>
  </svg>
);

export default TwitterIcon;