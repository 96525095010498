import React from 'react';

const LinkedinIcon = ({ width, height }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 26 26">
    <g fill="none" fillRule="evenodd" transform="translate(1 1)">
      <circle cx="12" cy="12" r="12" stroke="#C83E3E" />
      <path d="M6.881 16.868h2.27v-6.814h-2.27zm9.719-6.19c-.477-.521-1.11-.783-1.89-.783-.289 0-.553.034-.79.103a2.11 2.11 0 0 0-.598.3c-.168.13-.29.248-.384.358-.094.11-.185.228-.269.365v-.967h-2.265l.004.33c.012.222.012.896.012 2.036 0 1.135-.007 2.618-.016 4.447h2.265v-3.801c0-.233.024-.42.072-.56.099-.232.243-.43.435-.588.196-.158.434-.232.73-.232.39 0 .683.134.866.405.184.274.28.648.28 1.135v3.641h2.259V12.96c0-1.003-.233-1.764-.71-2.282M8.034 6.775c-.384 0-.691.11-.926.331-.238.221-.358.504-.358.84 0 .339.12.617.348.84.228.228.53.339.905.339h.014c.384 0 .696-.11.931-.339.236-.223.353-.501.348-.84a1.15 1.15 0 0 0-.35-.84c-.228-.22-.533-.33-.912-.33" fill="#C83E3E" />
    </g>
  </svg>
);

export default LinkedinIcon;