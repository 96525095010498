import React from 'react';

const PhoneIcon = ({ width, height }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16">
    <g fill="none" fillRule="evenodd" stroke="#C83E3E">
      <path d="M3.656.516h-.002a1.685 1.685 0 0 0-1.432.492l-.999.997c-.893.893-.97 2.398-.17 4.31.689 1.644 1.954 3.435 3.579 5.058 1.622 1.624 3.413 2.888 5.054 3.574.884.369 1.68.553 2.37.553.808 0 1.47-.25 1.942-.723l1.005-1.009a1.68 1.68 0 0 0 .481-1.424 1.71 1.71 0 0 0-.833-1.247l-3.388-1.95c-.619-.36-1.611-.16-2.164.398a3.303 3.303 0 0 1-.2.225.821.821 0 0 1-.119.102c-.19.13-.384.186-.632-.028a31.25 31.25 0 0 1-1.021-.95 31.903 31.903 0 0 1-.953-1.022c-.059-.682-.059-.682-.006-.647l.033-.037.057-.06.19-.186c.574-.572.766-1.576.409-2.202L4.906 1.353a1.684 1.684 0 0 0-1.25-.837zm4.538 8.546z" />
      <path strokeLinecap="round" strokeLinejoin="round" d="M8.972 9.612l6.098 3.575M6.19 7.024l-3.638-6.02" />
    </g>
  </svg>
);

export default PhoneIcon;