import classnames from 'classnames';
import { RichText } from 'prismic-reactjs';
import React, { useEffect, useRef, useState } from 'react';
import shared from 'styles/shared.module.scss';
import css from './register.module.scss';

const SubForm = ({ form }) => {
  const createdRef = useRef();
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (!createdRef.current) {
        let script = document.createElement(`script`);
        script.defer = true;
        script.onload = () => {
          window.hbspt.forms.create({
            region: 'na1',
            portalId: '3823430',
            formId: form.form_id,
            target: `#form-${form.form_id}`,
          });
          setShowForm(true);
          createdRef.current = true;
        };
        script.src = `//js.hsforms.net/forms/v2.js`;
        document.head.appendChild(script);
      }
    }
  }, [form.form_id]);

  return (
    showForm && (
      <div className={css.subform}>
        <div className={shared.maxwidth}>
          <h4 className={classnames(shared.sectionType, css.subformLabel)}>
            {form.label}
          </h4>
          <div className={css.form} id={`form-${form.form_id}`} />
        </div>
      </div>
    )
  );
};

const RegisterSection = ({ title, description, formId, subForms }) => {
  const createdRef = useRef();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (!createdRef.current) {
        let script = document.createElement(`script`);
        script.defer = true;
        script.onload = () => {
          window.hbspt.forms.create({
            region: 'na1',
            portalId: '3823430',
            formId: formId,
            target: `#form-${formId}`,
          });
          createdRef.current = true;
        };
        script.src = `//js.hsforms.net/forms/v2.js`;
        document.head.appendChild(script);
      }
    }
  }, [formId]);

  return (
    <section className={css.host}>
      <div className={shared.maxwidth}>
        <h2 className={classnames(shared.sectionTitle, css.title)}>{title}</h2>
        <div className={classnames(shared.sectionDescription, css.description)}>
          <RichText render={description} />
        </div>
        <div
          id={`form-${formId}`}
          className={classnames(css.form, css.mainForm)}
        />
      </div>
      {subForms.map((form, idx) => (
        <SubForm key={idx} form={form} />
      ))}
    </section>
  );
};

export default RegisterSection;
