import React, { useState } from 'react';

import { getCookie } from 'utils/cookies';

import css from './form-section.module.scss';
import shared from 'styles/shared.module.scss';

const PORTAL_ID = '3823430';

const FormSection = ({ sectionId, label, title, form, conversions }) => {
  const [email, setEmail] = useState('');
  const [consent, setConsent] = useState(false);
  const [sending, setSending] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');

  const { id, privacy, success } = form;

  const onSubmit = evt => {
    evt.preventDefault();

    setSending(true);

    const formUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${PORTAL_ID}/${id}`;
    const data = {
      submittedAt: Date.now(),
      fields: [
        {
          name: 'email',
          value: email,
        },
      ],
      context: {
        hutk: getCookie('hubspotutk'),
      },
      legalConsentOptions: {
        consent: {
          consentToProcess: true,
          text: privacy,
          communications: [
            {
              value: true,
              subscriptionTypeId: 999,
              text: privacy,
            },
          ],
        },
      },
    };

    const final_data = JSON.stringify(data);
    const xhr = new XMLHttpRequest();

    xhr.open('POST', formUrl);
    xhr.setRequestHeader('Content-Type', 'application/json');

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        setStatusMessage(success);
      }

      setSending(false);
    };

    xhr.send(final_data);
  };

  const valid = email && consent;

  return (
    <section className={css.host}>
      {sectionId ? <div className={css.anchor} id={sectionId} /> : null}
      <div className={shared.maxwidth}>
        <h4 className={css.type}>{label}</h4>
        <h3 className={css.title}>{title}</h3>
        <form className={css.form} onSubmit={onSubmit}>
          <div className={css.inputContainer}>
            <label htmlFor="form-email" className={css.inputLabel}>
              Email
            </label>
            <input
              aria-label="Email"
              type="email"
              name="email"
              placeholder="Enter your email"
              className={`${email ? css.dirty : ''} ${css.formInput}`}
              onChange={evt => setEmail(evt.target.value)}
              required
            />
          </div>
          <div className={css.privacyWrapper}>
            <input
              type="checkbox"
              name="privacy"
              aria-label="privacy"
              value={consent}
              className={css.privacyInput}
              onChange={evt => setConsent(evt.target.checked)}
            />
            <span className={css.privacyMessage}>{privacy}</span>
          </div>
          <button
            type="submit"
            className={css.formBtn}
            disabled={!valid || sending}
          >
            {sending ? 'Sending...' : 'Submit'}
          </button>
          {statusMessage ? (
            <>
              {conversions.linkedin ? (
                <img
                  height="1"
                  width="1"
                  style={{ display: 'none' }}
                  alt=""
                  src={conversions.linkedin}
                />
              ) : null}
              <div className={css.formMessage}>{statusMessage}</div>
            </>
          ) : null}
        </form>
      </div>
    </section>
  );
};

export default FormSection;
