import React from 'react';

const ArrowIcon = ({ width, height, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    className={className}
    viewBox="0 0 5 10"
  >
    <path
      fill="none"
      stroke="inherit"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M0 1l4.23 4L0 9"
    />
  </svg>
);

export default ArrowIcon;
