import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import SubMenu from 'components/SubMenu';

const ApplicationsMenu = ({ onSelect, mobile }) => {
  const data = useStaticQuery(graphql`
    query {
      prismic {
        allMenus {
          edges {
            node {
              applications_intro
              applications_items {
                application_item_logo
                application_item_title
                application_item_description
                application_item_link {
                  ... on PRISMIC_Application {
                    _meta {
                      uid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const applicationMenu = data.prismic.allMenus.edges[0];
  if (!applicationMenu) return null;

  const applications = applicationMenu.node.applications_items.map(item => ({
    uid: item.application_item_link
      ? item.application_item_link._meta.uid
      : null,
    title: item.application_item_title,
    description: item.application_item_description,
    icon: item.application_item_logo,
  }));

  return (
    <SubMenu
      intro={applicationMenu.node.applications_intro}
      items={applications}
      slug="applications"
      onSelect={onSelect}
      mobile={mobile}
    />
  );
};

export default ApplicationsMenu;
