import React from 'react';
import { Link } from 'gatsby';
import classnames from 'classnames';

import flagEN from 'images/flag-en.png';
import flagJP from 'images/flag-jp.png';

import css from './language-switcher.module.scss';

const LanguageSwitcher = ({ urls, className }) => {
  return (
    <div className={classnames(css.host, className)}>
      {urls.map((url, idx) => (
        <Link className={css.lang} key={idx} to={url.url}>
          <img
            className={css.flag}
            src={url.lang === 'en' ? flagEN : flagJP}
            alt={url.lang}
          />
          <span>{url.lang.toUpperCase()}</span>
        </Link>
      ))}
    </div>
  );
};

export default LanguageSwitcher;
