import React, { useState } from 'react';
import classnames from 'classnames';
import { RichText } from 'prismic-reactjs';

import ArrowIcon from 'components/Icons/ArrowIcon';

import css from './faq-section.module.scss';
import shared from 'styles/shared.module.scss';

const FaqSection = ({ label, title, faqs }) => {
  const [selectedIdx, setSelectedIdx] = useState(-1);

  const onSelect = idx => {
    setSelectedIdx(prevIdx => (prevIdx === idx ? -1 : idx));
  };

  return (
    <section className={`${css.host} ${shared.maxwidth}`}>
      <h4 className={shared.sectionType}>{label}</h4>
      <h3 className={shared.sectionTitle}>{title}</h3>
      <ul className={css.list}>
        {faqs.map((faq, idx) => (
          <li
            key={idx}
            className={classnames(css.item, {
              [css.selected]: selectedIdx === idx,
            })}
          >
            <div
              className={css.box}
              role="presentation"
              onClick={() => onSelect(idx)}
            >
              <span>{RichText.asText(faq.question)}</span>
              <ArrowIcon className={css.arrowIcon} />
            </div>
            <div className={css.wrapper}>
              <div className={css.content}>
                <RichText render={faq.answer} />
              </div>
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default FaqSection;
