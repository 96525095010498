import React from 'react';
import { RichText } from 'prismic-reactjs';

import ArrowIcon from 'components/Icons/ArrowIcon';

import css from './flow-section.module.scss';
import shared from 'styles/shared.module.scss';

const FlowSection = ({ type, title, steps }) => {
  return (
    <section className={`${css.host} ${shared.maxwidth}`}>
      <h4 className={shared.sectionType}>{RichText.asText(type)}</h4>
      <h3 className={shared.sectionTitle}>{RichText.asText(title)}</h3>
      <ul className={css.list}>
        {steps.map((step, idx) => (
          <li key={idx} className={css.step}>
            {step.image ? (
              <img src={step.image} className={css.stepImage} alt="step" />
            ) : (
              <div className={css.num}>{idx + 1}</div>
            )}
            <h4 className={css.stepTitle}>{RichText.asText(step.title)}</h4>
            <div className={css.stepDescription}>
              <RichText render={step.description} />
            </div>
            <div className={css.separator}>
              <ArrowIcon width={14} height={24} className={css.icon} />
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default FlowSection;
