import React from 'react';
import { RichText } from 'prismic-reactjs';

import Button from 'components/Button';
import Image from 'components/Image';

import css from './mosaic.module.scss';
import shared from 'styles/shared.module.scss';

const MosaicSection = ({
  type,
  title,
  description,
  images,
  hasBackground,
  ltr,
  buttons,
}) => {
  const topLeftImage = images[0] || {};
  const topRightImage = images[1] || {};
  const bottomImage = images[2] || {};

  const renderImages = (
    <div className={css.images}>
      <div className={css.imageRow}>
        <Image
          src={topLeftImage.url}
          className={css.image1}
          alt={topLeftImage.alt}
        />
        <Image
          src={topRightImage.url}
          className={css.image2}
          alt={topRightImage.alt}
        />
      </div>
      <Image
        src={bottomImage.url}
        className={css.image3}
        alt={bottomImage.alt}
      />
    </div>
  );
  const renderContent = (
    <div className={css.content}>
      <h4 className={shared.sectionType}>{RichText.asText(type)}</h4>
      <h2 className={shared.sectionTitle}>{RichText.asText(title)}</h2>
      <div className={shared.sectionDescription}>
        <RichText render={description} />
      </div>
      {buttons && buttons.length && (
        <div className={css.buttons}>
          {buttons.map(button =>
            button.button_anchor ? (
              <a
                href={button.button_anchor}
                key={button.button_anchor}
                className={css.buttonLink}
              >
                <Button className={css.button}>{button.button_label}</Button>
              </a>
            ) : (
              <a
                key={button.button_link.url}
                href={button.button_link.url}
                className={css.buttonLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button className={css.button}>{button.button_label}</Button>
              </a>
            )
          )}
        </div>
      )}
    </div>
  );

  return (
    <section
      className={`${css.host} ${ltr ? css.ltr : ''} ${
        !hasBackground ? css.noBackground : ''
      }`}
    >
      {hasBackground && <div className={css.background} />}
      <div className={`${css.container} ${shared.maxwidth}`}>
        {ltr ? renderContent : renderImages}
        {ltr ? renderImages : renderContent}
      </div>
    </section>
  );
};

export default MosaicSection;
