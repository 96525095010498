import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import SubMenu from 'components/SubMenu';

const ProjectsMenu = ({ onSelect, mobile }) => {
  const data = useStaticQuery(graphql`
    query {
      prismic {
        allMenus {
          edges {
            node {
              projects_intro
              projects_items {
                projects_item_description
                projects_item_link {
                  ... on PRISMIC_Project {
                    _meta {
                      uid
                    }
                  }
                }
                projects_item_logo
                projects_item_title
              }
            }
          }
        }
      }
    }
  `);

  const projectMenu = data.prismic.allMenus.edges[0];
  if (!projectMenu) return null;

  const projects = projectMenu.node.projects_items.map(item => ({
    uid: item.projects_item_link ? item.projects_item_link._meta.uid : null,
    title: item.projects_item_title ?? [],
    description: item.projects_item_description ?? [],
    icon: item.projects_item_logo,
  }));

  return (
    <SubMenu
      intro={projectMenu.node.projects_intro}
      items={projects}
      slug="projects"
      onSelect={onSelect}
      mobile={mobile}
    />
  );
};

export default ProjectsMenu;
